<template>
  <label
    class="PSCheckbox"
    :class="{
      'PSCheckbox--selected': modelValue,
      'PSCheckbox--switch': switchMode,
      'PSCheckbox--check': !switchMode,
      'PSCheckbox--with-bg': withBg,
      'PSCheckbox--disabled': disabled,
    }"
  >
    <input
      type="checkbox"
      :checked="modelValue"
      hidden
      @change="onChange"
    >
    <div class="PSCheckbox__label">
      {{ label }}
    </div>
  </label>
</template>

<script setup lang="ts">
const props = defineProps<{
  modelValue: boolean;
  label: string;
  switchMode?: boolean;
  withBg?: boolean;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
}>();

const onChange = (event) => {
  if (!props.disabled) {
    console.log('🚀 ~ onChange ~ event.target.checked:', event.target.checked);
    emit('update:modelValue', event.target.checked);
  }
};
</script>

<style lang="scss" scoped>
$padding-left: 32px;

.PSCheckbox {
  display: inline-block;
  vertical-align: middle;
  user-select: none;

  &__label {
    cursor: pointer;
    min-height: 20px;
    font-size: 20px;
    font-weight: 400;
    position: relative;
  }
}

.PSCheckbox--disabled .PSCheckbox__label {
  pointer-events: none;
  filter: grayscale(1);
}
.PSCheckbox--check .PSCheckbox__label {
  padding-left: $padding-left;
  &:before {
    width: 16px;
    height: 16px;
    content: '';
    position: absolute;
    left: 0;
    top: 2px;
    border: 2px solid $blue;
    background-color: #ffffff;
    transition: $tr / 2;
    border-radius: 3px;
  }
  &:after {
    position: absolute;
    content: '';
    opacity: 0;
    transition: $tr / 2;
    width: 12px * 0.9;
    height: 9px * 0.9;
    left: 4px;
    top: 7px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 11.314 8.4849997' fill='%23003366' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='translate(-6.76,-7.515)'%3E%3Cpath d='M 0,0 H 24 V 24 H 0 Z' fill='none' id='path1' /%3E%3Cpath d='m 11.003,16 -4.243,-4.243 1.414,-1.414 2.829,2.829 5.656,-5.657 1.415,1.414 z' id='path2' /%3E%3C/g%3E%3C/svg%3E");
  }

  @include hover {
    &::after {
      opacity: 0.4;
    }
  }
}
.PSCheckbox--switch .PSCheckbox__label {
  padding-left: 60px;
  &::before {
    content: '';
    width: 40px;
    height: 24px;
    background-color: $gray;
    border-radius: 12px;
    position: absolute;
    left: 0;
    top: 0;
    transition: $tr / 2;
  }
  &::after {
    content: '';
    position: absolute;
    left: 4px;
    top: 4px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #fff;
    transition: $tr / 2;
  }
}
.PSCheckbox--check.PSCheckbox--selected .PSCheckbox__label {
  &::before {
    border-color: $blue;
  }
  &::after {
    opacity: 1;
  }
}
.PSCheckbox--switch.PSCheckbox--selected .PSCheckbox__label {
  &::before {
    background-color: $blue;
  }
  &::after {
    transform: translateX(16px);
  }
}
.PSCheckbox--with-bg .PSCheckbox__label {
  background-color: $light-max;
  border-radius: 6px;
  padding: 7px 15px 7px 15px + $padding-left;
  border: 1px solid transparent;
  border-radius: 6px;
  outline: none;
  color: #000;
  height: 40px;

  &:focus {
    border-color: $blue;
  }

  &:before {
    left: 0 + 15px;
    top: 2px + 7px;
  }
  &:after {
    left: 4px + 15px;
    top: 7px + 7px;
  }
}
</style>
