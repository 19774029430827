export enum EInputTypes {
  Text = 'text',
  Password = 'password',
  Date = 'date',
  Tel = 'tel',
  Textarea = 'textarea',
  Number = 'number',
  Time = 'time',
  Email = 'email',
}

export enum EInputSizes {
  Big = 'big',
}

export enum EInputThemes {
  Clear = 'clear',
  Bordered = 'bordered',
  White = 'white',
}

export enum EInputMode {
  Default = 'default',
  Inline = 'inline',
}
