<template>
  <header class="header">
    <div class="header__logo">
      <img
        v-if="CompanyStore.company?.logo"
        class="header__logo-img"
        :class="
          CompanyStore.company?.caption?.length > 0
            ? 'header__logo-img-logo--text'
            : 'header__logo-img--logo'
        "
        :src="CompanyStore.company?.logo"
        alt=""
      >
      <template v-if="CompanyStore.company?.caption">
        {{ CompanyStore.company?.caption }}
      </template>
    </div>
    <HeaderNav />
    <HeaderActions />
  </header>
</template>
<script lang="ts" setup>
import HeaderNav from './HeaderNav.vue';
import HeaderActions from './HeaderActions.vue';
import { useCompanyStore, } from '@/store/Company.store';

const CompanyStore = useCompanyStore();
</script>

<style lang="sass" scoped>
.header
  position: relative
  height: 80px
  width: 100%
  border-bottom: 2px solid $light
  background-color: $light
  background-color: #fff
  padding: 16px 40px
  display: flex
  align-items: center

  &__logo
    flex: 0 1 auto
    font-family: sans-serif
    font-size: min(36px, 2.4vw)
    line-height: 24px
    font-weight: 700
    letter-spacing: -0.01em
    color: #000000
    display: flex
    align-items: center

    &-img
      max-height: 64px
      max-width: 320px
      overflow: hidden
      border-radius: 8px

      max-height: 64px
      flex: 0 0 auto
      margin-right: 12px

      +contain

      &--logo-text
        width: min(48px, 3.6vw)

      &--logo
        max-width: 320px



    img
</style>
