export const getTimeValues = () => {
  // Создаем массив для хранения временных значений
  const times: string[] = [];

  // Генерируем все возможные временные значения (каждые 15 минут)
  for (let hour = 0; hour < 24; hour++) {
    for (const minute of [0, 15, 30, 45,]) {
      // Формируем строку вида "HH:mm" без дополнительных нулей
      const formattedTime = `${hour}:${minute.toString().padStart(2, '0')}`;
      times.push(formattedTime); // Добавляем время в массив
    }
  }

  // Преобразуем массив строк в массив объектов с полями label и value
  return times.map((time) => ({
    label: time, // Отображаемое значение
    value: time, // Значение для использования в логике
  }));
};
