import { reactive, ref, } from 'vue';

export class ConfirmationDialogService {
  private static instance: ConfirmationDialogService;
  public isVisible = ref(false);
  public resolvePromise!: (value: boolean) => void;

  public data = reactive({
    title: '',
    message: '',
    confirmButtonText: 'Подтвердить',
  });

  // Получение экземпляра сервиса (Singleton)
  public static getInstance(): ConfirmationDialogService {
    if (!ConfirmationDialogService.instance) {
      ConfirmationDialogService.instance = new ConfirmationDialogService();
    }
    return ConfirmationDialogService.instance;
  }

  // Метод для открытия диалога
  public openDialog(options: {
    title: string;
    message: string;
    confirmButtonText?: string;
  }): Promise<boolean> {
    this.data.title = options.title;
    this.data.message = options.message;
    this.data.confirmButtonText = options.confirmButtonText || 'Подтвердить';
    this.isVisible.value = true;

    return new Promise((resolve) => {
      this.resolvePromise = resolve;
    });
  }

  // Метод для закрытия диалога с результатом
  public closeDialog(confirmed: boolean): void {
    this.isVisible.value = false;
    this.resolvePromise(confirmed);
  }
}

// Экспорт экземпляра сервиса
export const confirmationDialogService =
  ConfirmationDialogService.getInstance();
