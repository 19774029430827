import { defineStore, } from 'pinia';
import { OpenAPI, Service, UserAddSchema, type UserSchema, } from '@/schemas';

export interface IUser {
  email: string;
  password: string;
  username?: string;
}

interface IAuth {
  access_token?: string;
  refresh_token?: string;
  token_type?: string;
}

type IAuthResponse = UserSchema & IAuth;

export const useAuthStore = defineStore('Auth', () => {
  const localUser = localStorage.getItem('user');
  const user = ref<IAuthResponse | null>(
    localUser ? JSON.parse(localUser) : null
  );

  if (user.value?.access_token) {
    OpenAPI.TOKEN = 'Bearer ' + user.value?.access_token;
  }

  const isLoggedIn = computed(() => {
    return !!user.value?.access_token;
  });

  const initialState = user
    ? { status: { loggedIn: true, }, user, }
    : { status: { loggedIn: false, }, user: null, };

  const login = async (userLogin: IUser) => {
    Service.userLogin({
      requestBody: {
        email: userLogin.email,
        password: userLogin.password,
      },
    })
      .then((response) => {
        localStorage.setItem('user', JSON.stringify(response));
        user.value = response;

        OpenAPI.TOKEN = 'Bearer ' + response.access_token;

        return response;
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const logout = async () => {
    if (user.value !== null) {
      Service.userLogout();
      user.value = null;
      localStorage.removeItem('user');
    }
  };

  const register = async (user: IUser) => {
    // TODO: поставить норм путь
  };

  const setNewAccessToken = (token: string) => {
    if (user.value) {
      user.value.access_token = token;
    }
    OpenAPI.TOKEN = 'Bearer ' + token;
    localStorage.setItem('user', JSON.stringify(user.value));
  };

  return {
    user,
    initialState,
    login,
    logout,
    register,
    isLoggedIn,
    setNewAccessToken,
  };
});
