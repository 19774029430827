import { Service, type CompanySchema, } from '@/schemas';
import { defineStore, } from 'pinia';

export const useCompanyStore = defineStore('CompanyStore', () => {
  const company = ref<CompanySchema>();

  /** Обновить данные company в сторе */
  const loadCompany = async () => {
    return await Service.companyGet();
  };

  async function updateCompany() {
    console.log('updateCompany');
    await loadCompany().then((res) => (company.value = res.company));
  }

  updateCompany();

  return { company, loadCompany, updateCompany, };
});
