import { createApp, } from 'vue';
import { createPinia, } from 'pinia';
import App from './App.vue';
import router from './router';
import 'vue-global-api';
import vClickOutside from 'click-outside-vue3';
import VueDragScroller from 'vue-drag-scroller';
import FileUpload from '@yazan.alnughnugh/file-upload';
import { createYmaps, } from 'vue-yandex-maps';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(timezone);

// import pluginDay from '@/plugins/day';

// import axios, { Axios, } from 'axios';
// app.config.globalProperties.$http = Axios;
// axios.defaults.baseURL = 'http://stem.yokel.tech/api/v1/';

const app = createApp(App);
const pinia = createPinia();

app.provide('dayJS', dayjs); // provide dayJS

// Global filters
app.config.globalProperties.$fn = {
  formatNumber(number: string | number, params = {}) {
    return Intl.NumberFormat('ru-RU', params).format(Number(number || 0));
  },
  formatPrice(
    number: string | number,
    params = { style: 'currency', currency: 'RUB', }
  ) {
    if (number === undefined) {
      return '—';
    }
    return Intl.NumberFormat('ru-RU', params).format(Number(number || 2));
  },
  dateFormat(date) {
    return date ? dayjs(date).format('DD.MM.YYYY') : '';
  },
  dateFormatFull(date) {
    return dayjs(date).format('DD.MM.YYYY HH:mm');
  },
  dateFormatInTime(date) {
    return dayjs(date).format('HH:mm');
  },
};

// Global components
import SvgIcon from '@/components/SvgIcon.vue';
app.component('SvgIcon', SvgIcon);

// Tippy
import { plugin as VueTippy, } from 'vue-tippy';
import 'tippy.js/dist/tippy.css'; // optional for styling

app.use(
  VueTippy,
  // optional
  {
    directive: 'tippy', // => v-tippy
    component: 'tippy', // => <tippy/>
    componentSingleton: 'tippy-singleton', // => <tippy-singleton/>,
    defaultProps: {
      // appendTo: () => document.getElementById('app'),
      appendTo: () => document.getElementById('tippy-teleport'),
      placement: 'auto-end',
      allowHTML: true,
    }, // => Global default options * see all props
  }
);

export const sidebarToggler = ref(true);
// Import the CSS or use your own!
import 'vue-toastification/dist/index.css';

import toast from 'vue-toastification';

app.use(toast, { position: 'bottom-right', });

function checkElement(el, elemTarget) {
  if (
    el.tagName == 'BODY' ||
    !el ||
    !elemTarget ||
    elemTarget.classList.contains('event-stop')
  ) {
    return false;
  }

  if (elemTarget === el) {
    return true;
  } else {
    return checkElement(el, elemTarget.parentNode);
  }
}
function handleMouseDown(event, el, binding, started, startPos) {
  if (!checkElement(el, event.target)) {
    return;
  } // only consider releases of the element or its children

  if (event.button !== 0) {
    return;
  } // only consider left mouse button clicks
  started.value = true;
  startPos.value = { x: event.clientX, y: event.clientY, };
}

function handleMouseUp(event, el, binding, started, startPos) {
  if (!checkElement(el, event.target)) {
    return;
  } // only consider releases of the element or its children

  if (event.button !== 0) {
    return;
  } // only consider left mouse button releases

  if (isClickValid(event, el, binding, started, startPos)) {
    binding.value();
  }
}

function isClickValid(event, el, binding, started, startPos) {
  const distanceX = Math.abs(event.clientX - startPos.value.x);
  const distanceY = Math.abs(event.clientY - startPos.value.y);
  return distanceX <= 100 && distanceY <= 100;
}

app.directive('clickTarget', {
  mounted(el, binding) {
    const started = ref(false);
    const startPos = ref({ x: 0, y: 0, });

    el.addEventListener('mousedown', (event) =>
      handleMouseDown(event, el, binding, started, startPos)
    );
    el.addEventListener('mouseup', (event) =>
      handleMouseUp(event, el, binding, started, startPos)
    );
  },
  unmounted(el) {
    el.removeEventListener('mousedown', handleMouseDown);
    el.removeEventListener('mouseup', handleMouseUp);
  },
  updated(el, binding) {
    el.removeEventListener('mousedown', handleMouseDown);
    el.removeEventListener('mouseup', handleMouseUp);
    const started = ref(false);
    const startPos = ref({ x: 0, y: 0, });

    el.addEventListener('mousedown', (event) =>
      handleMouseDown(event, el, binding, started, startPos)
    );
    el.addEventListener('mouseup', (event) =>
      handleMouseUp(event, el, binding, started, startPos)
    );
  },
});

// MOUNT

app.use(
  createYmaps({
    apikey: '70303425-9ae5-491a-bacd-19ae703ca0e6',
  })
);

app.component('FileUpload', FileUpload);
app.component('VueDatePicker', VueDatePicker);
// app.use(auth);
app.use(VueDragScroller);
app.use(pinia);
app.use(router);
app.use(vClickOutside);
app.mount('#app');
