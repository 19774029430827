<template>
  <div
    v-if="isOpened"
    class="confirmation-dialog-overlay"
    @click.self="closeDialog(false)"
  >
    <div class="confirmation-dialog">
      <div class="confirmation-dialog__header">
        <h3>{{ service.data.title }}</h3>
      </div>
      <div class="confirmation-dialog__content">
        <p v-html="service.data.message" />
      </div>
      <div class="confirmation-dialog__actions">
        <button
          class="btn btn--secondary"
          @click="closeDialog(false)"
        >
          Отмена
        </button>
        <button
          class="btn btn--primary"
          @click="closeDialog(true)"
        >
          {{ service.data.confirmButtonText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { confirmationDialogService, } from './ConfirmationDialogService';
const service = confirmationDialogService;

// Закрытие диалога с передачей результата
function closeDialog(confirmed: boolean) {
  service.closeDialog(confirmed);
}

const isOpened = computed(() => service.isVisible.value);
</script>

<style scoped lang="scss">
.confirmation-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.confirmation-dialog {
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: min(600px, 90%);
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.confirmation-dialog__header h3 {
  margin: 0 0 10px 0;
  font-size: 1.2rem;
  font-weight: bold;
}

.confirmation-dialog__content p {
  margin: 0 0 20px 0;
  font-size: 1rem;
  color: #333;
}

.confirmation-dialog__actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.btn {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.btn--primary {
  background-color: $blue;
  color: white;
}

.btn--secondary {
  background-color: #ccc;
  color: black;
}

// .confirmation-dialog-overlay {
//   display: none; // По умолчанию скрыт
// }

// .confirmation-dialog-overlay[v-if='true'] {
//   display: flex; // Отображается при isVisible = true
// }
</style>
