<template>
  <LoginView v-if="!useAuthStore().isLoggedIn" />
  <template v-else>
    <HeaderBlock />
    <div
      v-if="activeApp"
      id="app-grid"
      class="app-grid"
    >
      <router-view v-slot="{ Component }">
        <keep-alive>
          <component :is="Component" />
        </keep-alive>
      </router-view>
    </div>
    <ConfirmDialog />
  </template>
</template>

<script lang="ts" setup>
import LoginView from './components/LoginView.vue';
import HeaderBlock from '@/components/Header/HeaderWrap.vue';
import { useAuthStore, } from './store/Auth.store';
import ConfirmDialog from './components/ConfirmDialog/ConfirmDialog.vue';

const activeApp = ref(false);
onMounted(() => {
  activeApp.value = true;
  const zoomApp = () => {
    if (window.innerWidth < 1920) {
      const wrap = document.querySelector('#app') as HTMLElement;
      const body = document.querySelector('body') as HTMLElement;
      wrap.removeAttribute('style');
      setTimeout(() => {
        const zoom = window.innerWidth / 1920;
        window.appZoom = zoom;
        wrap.style.height = `${wrap.clientHeight / zoom}px`;
        wrap.style.setProperty('transform', `scale(${zoom})`);
        wrap.style.setProperty('--moz-transform', `scale(${zoom})`);
        wrap.style.setProperty('--vh100', `${wrap.clientHeight}px`);
        body.style.setProperty('--zoom', `${zoom}`);
      }, 0);
    }
  };
  let timer: ReturnType<typeof setTimeout>;

  zoomApp();
  window.addEventListener('resize', function () {
    clearTimeout(timer);
    timer = setTimeout(zoomApp, 100);
  });
});
</script>

<style lang="sass">
@import "styles/main.sass"
:root
  --zoom: 1
#app
  height: 100vh
  transform-origin: 0 0
  .dp__overlay_col
    font-size: 14px
.app-grid
  display: flex
  align-items: stretch
  height: calc(var(--vh100) - 80px)
  width: 1920px
  overflow: hidden


  +miw(1920)
    width: 100%

  &__content
    overflow: hidden
    flex: 1 1 auto
</style>
