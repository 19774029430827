<template>
  <button
    ref="htmlWrap"
    class="btn"
    :disabled="disabled"
    :class="[
      `btn-${theme}`,
      `btn--size-${size}`,
      {
        'btn-circle-icon': circleIcon,
        'btn-square-icon': squareIcon,
        'btn--rounded': isRounded,
        'btn--full-width': isFullWidth,
        'btn--disabled': disabled,
        'btn--loading': loading,
      },
    ]"
    @click="onClick"
  >
    <SvgIcon
      v-if="icon"
      :name="icon"
      :style="iconTranslate ? `transform: translate(${iconTranslate})` : ''"
    />
    <slot v-else />
  </button>
</template>

<script setup lang="ts">
import { useTippy, } from 'vue-tippy';
import { EButtonTheme, EButtonSize, } from './PSButton.enum';

const props = withDefaults(
  defineProps<{
    theme: EButtonTheme;
    size: EButtonSize;
    isFullWidth?: boolean;
    isRounded?: boolean;
    circleIcon?: string;
    squareIcon?: string;
    iconTranslate?: string;
    disabled?: boolean;
    tooltip?: string;
    loading?: boolean;
  }>(),
  {
    theme: EButtonTheme.Blue,
    size: EButtonSize.Default,
  }
);

const emit = defineEmits<{
  (e: 'click'): Event;
}>();

const icon = computed(() => props.squareIcon || props.circleIcon);

const htmlWrap = ref();

if (props.tooltip) {
  useTippy(htmlWrap, {
    content: props.tooltip,
  });
}

function onClick() {
  if (!props.disabled && !props.loading) {
    emit('click');
  }
}
</script>

<style lang="sass" scoped>
@import './Button.scss'
</style>
